<template>
  <b-overlay :show="show">
    <div class="my-register-page" style="font-size: 13px">
      <div class="my-container">
        <b-row class="mb-2">
          <b-col>
            <h1>{{ $t("inspectionbook") }}</h1>
          </b-col>
        </b-row>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">2 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-table-simple bordered responsive>
            <b-tbody>
              <b-tr variant="primary">
                <b-td colspan="4" class="text-center">
                  <span style="font-size: 18px; font-weight: bold">{{
                    $t("DocumentInfo")
                  }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("inn") }}
                </b-td>
                <b-td>
                  {{ Request.contractor.innOrPinfl }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("name") }}
                </b-td>
                <b-td>
                  {{ Request.contractor.fullName }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oblast") }}
                </b-td>
                <b-td>
                  {{ Request.contractor.region }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("region") }}
                </b-td>
                <b-td>
                  {{ Request.contractor.district }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oked") }}
                </b-td>
                <b-td colspan="3">
                  {{ Request.contractor.oked }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("orderedOrganization") }}
                </b-td>
                <b-td>
                  {{ Request.orderedOrganization }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("inspectionOrganization") }}
                </b-td>
                <b-td>
                  {{ Request.inspectionOrganization }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("authorizedOrganization") }}
                </b-td>
                <b-td colspan="3">
                  {{ Request.authorizedOrganization }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-row class="mt-3">
          <b-col cols="12" sm="12" md="6">
            <custom-input
              :disabled="Request.statusId == 2"
              :placeholder="$t('inspectionOrganizationName')"
              v-model="Request.inspectionOrganizationName"
              :label="$t('inspectionOrganizationName')"
            ></custom-input>
          </b-col>
          <b-col cols="12" sm="12" md="6">
            <custom-input
              :disabled="Request.statusId == 2"
              :placeholder="$t('inspectionOrganizationCeo')"
              v-model="Request.inspectionOrganizationCeo"
              :label="$t('inspectionOrganizationCeo')"
            ></custom-input>
          </b-col>
         
        </b-row>
        <b-row class="mt-3">
           <b-col cols="12" sm="12" md="6">
            <custom-input
              :disabled="Request.statusId == 2"
              :placeholder="$t('inspectionOrganizationPostalAddres')"
              v-model="Request.inspectionOrganizationPostalAddres"
              :label="$t('inspectionOrganizationPostalAddres')"
            ></custom-input>
          </b-col>
          <b-col cols="12" sm="12" md="6">
            <custom-input
              :disabled="Request.statusId == 2"
              :placeholder="$t('inspectionOrganizationAddres')"
              v-model="Request.inspectionOrganizationAddres"
              :label="$t('inspectionOrganizationAddres')"
            ></custom-input>
          </b-col>
          
        </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">3 - {{ $t("punkt") }}</h5>
            </b-col>
            <b-col class="mt-2 mb-2" style="text-align: right">
              <b-button
                v-if="Request.statusId != 2"
                @click="AddNewInspectors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="6" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("inspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("employeeName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr style="background-color:white" v-for="(item, index) in RequestTable" :key="index">
                    <b-td>
                      {{ item.fullName }}
                    </b-td>

                    <b-td>
                      <!-- {{ item.certificateNumber }} -->
                      <custom-input
                       :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('certificateNumber')"
                        v-model="item.certificateNumber"
                      ></custom-input>
                    </b-td>
                    <b-td>
                      <!-- {{ item.certificateExpiration }} -->
                      <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('certificateExpiration')"
                        placeholder="00.00.0000"
                        v-model="item.certificateExpiration"
                      ></custom-date-picker>
                    </b-td>
                    <b-td>
                      <!-- {{ item.dutyNumber }} -->
                      <custom-input
                       :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('inspectionOrganizationPostalAddres')"
                        v-model="item.dutyNumber"
                      ></custom-input>
                    </b-td>
                    <b-td>
                      <!-- {{ item.dutyExpiration }} -->
                      <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('dutyExpiration')"
                        placeholder="00.00.0000"
                        v-model="item.dutyExpiration"
                      ></custom-date-picker>
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link @click="DeleteInspector(item, index)">
                        <b-icon font-scale="1.5" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">4 - {{ $t("punkt") }}</h5>
            </b-col>
            <b-col class="mt-2 mb-2" style="text-align: right">
              <b-button
                v-if="Request.statusId != 2"
                @click="AddInspectors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="10" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("involvedInspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("address") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr style="background-color:white"
                    v-for="(item, index) in Request.involvedInspectors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.address }}
                    </b-td>
                    <b-td>
                      {{ item.inspectorFullName }}
                    </b-td>

                    <b-td>
                      {{ item.dutyNumber }}
                    </b-td>
                    <b-td>
                      {{ item.dutyExpiration }}
                    </b-td>

                    <b-td>
                      {{ item.dutyIssuingOrganization }}
                    </b-td>
                    <b-td>
                      {{ item.certificateNumber }}
                    </b-td>

                    <b-td>
                      {{ item.certificateIssuingOrganization }}
                    </b-td>
                    <b-td>
                      {{ item.certificateExpiration }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link @click="DeleteInvolvedInspector(item, index)">
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: right">
              <b-button
                v-if="Request.statusId != 2"
                @click="AddAuditors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="8" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("InvolvedAuditors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseDate") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("auditorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr style="background-color:white"
                    v-for="(item, index) in Request.involvedAuditors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.licenseDate }}
                    </b-td>
                    <b-td>
                      {{ item.licenseNumber }}
                    </b-td>
                    <b-td>
                      {{ item.licenseExpiration }}
                    </b-td>
                    <b-td>
                      {{ item.auditorFullName }}
                    </b-td>
                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td>
                      {{ item.contractExpiration }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link @click="DeleteAuditors(item, index)">
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: right">
              <b-button
                v-if="Request.statusId != 2"
                @click="AddSpecialists"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="5" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("specialists")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorsName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("workplace") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("directionOfActivity") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr style="background-color:white"
                    v-for="(item, index) in Request.specialists"
                    :key="index"
                  >
                    <b-td>
                      {{ item.fullName }}
                    </b-td>

                    <b-td>
                      {{ item.workplace }}
                    </b-td>
                    <b-td>
                      {{ item.directionOfActivity }}
                    </b-td>

                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link @click="DeleteSpecialists(item, index)">
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">5 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <custom-input
                class="inputStyle"
                :disabled="Request.statusId == 2"
                :placeholder="$t('criminalCaseInfo')"
                v-model="Request.criminalCaseInfo"
                :label="$t('criminalCaseInfo')"
              ></custom-input>
            </b-col>
          </b-row>
        </div>
         <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">6 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12">
             <v-select
              :disabled="Request.statusId == 2"
              multiple
              :placeholder="$t('controlFunctions')"
              class="my-select"
              :options="ControlFunctionList"
              :reduce="(item) => item.value"
              label="text"
              v-model="Request.controlFunctions"
              @input="ControlFunctionsChange"
            ></v-select>
            </b-col>
          </b-row>
        </div>
         <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">7 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
          <b-col cols="12" sm="12" md="4">
            <custom-date-picker
              :disabled="Request.statusId == 2"
              :label="$t('docDate')"
              placeholder="00.00.0000"
              v-model="Request.docDate"
            ></custom-date-picker>
          </b-col>

          <b-col cols="12" sm="12" md="4">
            <custom-date-picker
              :disabled="Request.statusId == 2"
              :label="$t('startDate')"
              placeholder="00.00.0000"
              v-model="Request.startDate"
            ></custom-date-picker>
          </b-col>

          <b-col cols="12" sm="12" md="4">
            <custom-date-picker
              :disabled="Request.statusId == 2"
              :label="$t('endDate')"
              placeholder="00.00.0000"
              v-model="Request.endDate"
            ></custom-date-picker>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col cols="12" sm="12" md="4">
            <custom-input
              :disabled="Request.statusId == 2"
              placeholder="0"
              v-model="Request.checkDaysNumber"
              :label="$t('checkDaysNumber')"
            ></custom-input>
          </b-col>
        </b-row>
        </div>
        <b-row class="mb-3">
          <b-col col="6">
            <b-button
              @click="back"
              style="width: 150px"
              variant="outline-danger"
            >
              {{ $t("back") }}
            </b-button>
          </b-col>
          <b-col col="6" style="text-align: right">
            <b-button
              v-if="Request.statusId != 2"
              @click="SaveData"
              style="width: 150px"
              variant="outline-success"
            >
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-modal
        v-model="modalInspectorsInfo"
        hide-footer
        size="lg"
        no-close-on-backdrop
        hide-header
        centered
      >
        <div
          style="text-align: right; margin-right: 10px; margin-top: -5px; margin-bottom: 5px; border-bottom: 1px solid lightgray"
        >
          <span
            @click="modalInspectorsInfo = false"
            style="cursor:pointer; font-size: 30px"
          >
            &times;
          </span>
        </div>
        <div class="mb-2">
          <custom-input
            :placeholder="$t('search')"
            v-model="filter1.search"
            :label="$t('search')"
          ></custom-input>
        </div>
        <b-table
          :items="items"
          :current-page="filter1.page"
          :per-page="filter1.pageSize"
          bordered
          :fields="fields"
          :filter="filter1.search"
        >
          <template #cell(isSelected)="{ item }">
            <div class="text-center">
              <b-form-checkbox v-model="item.isSelected"> </b-form-checkbox>
            </div>
          </template>
        </b-table>

        <div>
          <b-pagination
            v-model="filter1.page"
            :total-rows="filter1.totalRows"
            :per-page="filter1.pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <b-icon icon="chevron-double-left" size="18" />
            </template>
            <template #next-text>
              <b-icon icon="chevron-double-right" size="18" />
            </template>
          </b-pagination>
        </div>
        <div style="text-align: right">
          <b-button
            variant="outline-success"
            :disabled="isSave"
            @click="AddInspectorsTableInfo"
          >
            <b-spinner
              style="margin-left : 20px"
              v-if="isSave"
              small
            ></b-spinner>
            {{ $t("save") }}
          </b-button>
        </div>
      </b-modal>
      <b-modal
        size="lg"
        v-model="modal"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <b-row>
          <b-col>
            <h5>{{ $t("Add") }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('inspectorsName')"
              v-model="tabrow.fullName"
              :label="$t('inspectorsName')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('workplace')"
              v-model="tabrow.workplace"
              :label="$t('workplace')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('directionOfActivity')"
              v-model="tabrow.directionOfActivity"
              :label="$t('directionOfActivity')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('contractNumber')"
              v-model="tabrow.contractNumber"
              :label="$t('contractNumber')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <div style="text-align: right" class="mr-2">
            <b-button
              style="margin-right:5px"
              class="mr-2"
              variant="outline-danger"
              :disabled="isSave"
              @click="BackModal"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("back") }}
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-success"
              :disabled="isSave"
              @click="AddOked"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("save") }}
            </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal
        size="lg"
        v-model="modalAuditors"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <b-row>
          <b-col>
            <h5>{{ $t("InvolvedAuditors") }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('organizationName')"
              v-model="tabrowAuditors.organizationName"
              :label="$t('organizationName')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-date-picker
              :label="$t('licenseDate')"
              placeholder="00.00.0000"
              v-model="tabrowAuditors.licenseDate"
            ></custom-date-picker>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('licenseNumber')"
              v-model="tabrowAuditors.licenseNumber"
              :label="$t('licenseNumber')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-date-picker
              :label="$t('licenseExpiration')"
              placeholder="00.00.0000"
              v-model="tabrowAuditors.licenseExpiration"
            ></custom-date-picker>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('auditorFullName')"
              v-model="tabrowAuditors.auditorFullName"
              :label="$t('auditorFullName')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('contractNumber')"
              v-model="tabrowAuditors.contractNumber"
              :label="$t('contractNumber')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-date-picker
              :label="$t('contractExpiration')"
              placeholder="00.00.0000"
              v-model="tabrowAuditors.contractExpiration"
            ></custom-date-picker>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <div style="text-align: right" class="mr-2">
            <b-button
              class="mr-2"
              variant="outline-danger"
              style="margin-right:5px"
              :disabled="isSave"
              @click="modalAuditorsClose"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("back") }}
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-success"
              :disabled="isSave"
              @click="AddAuditorsTable"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("save") }}
            </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal
        size="lg"
        v-model="modalInspectors"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <b-row>
          <b-col>
            <h5>{{ $t("involvedInspectors") }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('organizationName')"
              v-model="tabrowInspectors.organizationName"
              :label="$t('organizationName')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('address')"
              v-model="tabrowInspectors.address"
              :label="$t('address')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('inspectorFullName')"
              v-model="tabrowInspectors.inspectorFullName"
              :label="$t('inspectorFullName')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              :placeholder="$t('dutyNumber')"
              v-model="tabrowInspectors.dutyNumber"
              :label="$t('dutyNumber')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-date-picker
              :label="$t('dutyExpiration')"
              placeholder="00.00.0000"
              v-model="tabrowInspectors.dutyExpiration"
            ></custom-date-picker>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('dutyIssuingOrganization')"
              v-model="tabrowInspectors.dutyIssuingOrganization"
              :label="$t('dutyIssuingOrganization')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('certificateNumber')"
              v-model="tabrowInspectors.certificateNumber"
              :label="$t('certificateNumber')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-input
              type="text"
              :placeholder="$t('certificateIssuingOrganization')"
              v-model="tabrowInspectors.certificateIssuingOrganization"
              :label="$t('certificateIssuingOrganization')"
            ></custom-input>
          </b-col>
          <b-col sm="12" lg="12" class="mt-1">
            <custom-date-picker
              :label="$t('certificateExpiration')"
              placeholder="00.00.0000"
              v-model="tabrowInspectors.certificateExpiration"
            ></custom-date-picker>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <div style="text-align: right" class="mr-2">
            <b-button
              class="mr-2"
              style="margin-right:5px"
              variant="outline-danger"
              :disabled="isSave"
              @click="modalInspectorsClose"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("back") }}
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-success"
              :disabled="isSave"
              @click="AddInspectorsTable"
            >
              <b-spinner
                style="margin-left : 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("save") }}
            </b-button>
          </div>
        </b-row>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import InspectionBookService from "@/services/inspectionbook.service";
import customDatePicker from "../../components/elements/customDatePicker.vue";
import customInput from "../../components/elements/customInput.vue";
import customSelect from "../../components/elements/customSelect.vue";
import vSelect from "vue-select";
import InspectorService from "@/services/inspector.service";
import ControlFunctionService from "@/services/controlfunction.service";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BLink,
  BOverlay,
  BFormRating,
} from "bootstrap-vue";
export default {
  data() {
    return {
      isLoading: false,
      Request: {},
      RequestTable: [],
      InspectionBook: {},
      InspectionResult: {},
      InspectorsList: [],
      ControlFunctionList: [],
      filter: {},
      items: [],
      filtesIns: {
        parentOrganizationId: 0,
        organizationId: 0,
        isOnlyRegistered: true,
        isHr: false,
        isPassedAttestation: true,
        isInitQuery: true,
        sortBy: "",
        orderType: "asc",
        page: 1,
        pageSize: 2000,
      },
      modal: false,
      modalInspectors: false,
      modalAuditors: false,
      modalInspectorsInfo: false,
      filters: {
        orderedOrganizationId: 0,
      },
      isSave: false,
      tabrow: {
        id: 0,
        inspectionBookId: 0,
        fullName: "",
        workplace: "",
        directionOfActivity: "",
        contractNumber: "",
        // Status: 0,
      },
      tabrowInspectors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        address: "",
        inspectorFullName: "",
        dutyNumber: "",
        dutyExpiration: "",
        dutyIssuingOrganization: "",
        certificateNumber: "",
        certificateIssuingOrganization: "",
        certificateExpiration: "",
      },
      tabrowAuditors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        licenseDate: "",
        licenseNumber: "",
        licenseExpiration: "",
        auditorFullName: "",
        contractNumber: "",
        contractExpiration: "",
      },
      filter1: {
        page: 1,
        pageSize: 10,
        totalRows: 1,
        search: "",
      },
      fields: [
        {
          key: "isSelected",
          label: this.$t("isSelected"),
        },
        { key: "employeeId", label: "ID", sort: true },
        { key: "fullName", label: this.$t("fullName"), sort: true },
      ],
      editedIndex1: -1,
      show: false,
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BLink,
    BOverlay,
    customDatePicker,
    customInput,
    BFormRating,
    customSelect,
    vSelect,
  },
  created() {
    this.Refresh();
  },
  methods: {
    DeleteInspector(index) {
      this.RequestTable.splice(index, 1);
    },
    DeleteInvolvedInspector(index) {
      this.Request.involvedInspectors.splice(index, 1);
    },
    DeleteSpecialists(index) {
      this.Request.specialists.splice(index, 1);
    },
    DeleteAuditors(index) {
      this.Request.involvedAuditors.splice(index, 1);
    },
    BackModal() {
      this.modal = false;
    },
    modalInspectorsClose() {
      this.modalInspectors = false;
    },
    modalAuditorsClose() {
      this.modalAuditors = false;
    },
    modalInspectorsInfoClose() {
      this.modalInspectorsInfo = false;
    },
    AddNewInspectors() {
      this.modalInspectorsInfo = true;
      this.isLoading = true;
      InspectorService.GetAsSelectList({
        orderedOrganizationId: this.Request.orderedOrganizationId,
        organizationId: this.Request.inspectionOrganizationId,
        isHr: false,
        isPassedAttestation: true,
        sortBy: this.filter1.sortBy,
        orderType: this.filter1.orderType,
        page: this.filter1.page,
        pageSize: this.filter1.pageSize,
      }).then((res) => {
        // this.items = res.data.rows;
        var ids = this.RequestTable.map((item) => item.employeeId);
        this.items = [];
        for (let i = 0; i < res.data.rows.length; i++) {
          this.items.push({
            fullName: res.data.rows[i].text,
            employeeId: res.data.rows[i].value,
            isSelected: ids.includes(res.data.rows[i].value),
          });
        }
        this.filter1.totalRows = this.items.length;
        // this.addInspectorList()
        this.isLoading = false;
      });
    },
    addInspectorList() {
      var ids = this.RequestTable.map((item) => item.employeeId);
      this.items = [];
      for (let i = 0; i < this.items.length; i++) {
        this.items.push({
          fullName: this.items[i].text,
          id: this.items[i].value,
          isSelected: ids.includes(this.items[i].value),
        });
      }
      this.filter1.totalRows = this.items.length;
    },
    AddSpecialists() {
      this.modal = true;
      this.tabrow = {
        id: 0,
        inspectionBookId: 0,
        fullName: "",
        workplace: "",
        directionOfActivity: "",
        contractNumber: "",
      };
    },
    AddAuditors() {
      this.modalAuditors = true;
      this.tabrowAuditors = {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        licenseDate: "",
        licenseNumber: "",
        licenseExpiration: "",
        auditorFullName: "",
        contractNumber: "",
        contractExpiration: "",
      };
    },
    AddInspectors() {
      this.modalInspectors = true;
      this.tabrowInspectors = {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        address: "",
        inspectorFullName: "",
        dutyNumber: "",
        dutyExpiration: "",
        dutyIssuingOrganization: "",
        certificateNumber: "",
        certificateIssuingOrganization: "",
        certificateExpiration: "",
      };
    },
    // InspectorsChange() {
    //   this.Request.inspectorNames = this.filter.InspectorsList(
    //     (item) => item.value === this.Request.inspectors
    //   )[0];
    // },
    ControlFunctionsChange() {
      this.Request.controlFunctionNames = this.filter.ControlFunctionList(
        (item) => item.value === this.Request.controlFunctions
      )[0];
    },
    Refresh() {
      this.show = true;
      if (this.$route.query.fromRequest) {
        InspectionBookService.GetByRequestId(this.$route.params.id)
          .then((res) => {
            this.Request = res.data;
            this.RequestTable = res.data.inspectors;
            this.show = false;
            ControlFunctionService.GetAsSelectList(
              (this.filters.orderedOrganizationId = this.Request.orderedOrganizationId)
            ).then((res) => {
              this.ControlFunctionList = res.data;
            });
          })
          .catch((error) => {
            this.makeToast(error.response.data, "error");
            this.show = false;
          });
      } else {
        InspectionBookService.Get(this.$route.params.id)
          .then((res) => {
            this.Request = res.data;
            this.RequestTable = res.data.inspectors;
            this.show = false;
            ControlFunctionService.GetAsSelectList(
              (this.filters.orderedOrganizationId = this.Request.orderedOrganizationId)
            ).then((res) => {
              this.ControlFunctionList = res.data;
            });
            // InspectorService.GetAsSelectList({
            //   orderedOrganizationId: this.Request.orderedOrganizationId,
            //   organizationId: this.Request.inspectionOrganizationId,
            //   isHr: false,
            //   isPassedAttestation: true,
            //   sortBy: "",
            //   orderType: "asc",
            //   page: 1,
            //   pageSize: 2000,
            // }).then((res) => {
            //   this.InspectorsList = res.data.rows;
            // });
          })
          .catch((error) => {
            this.makeToast(error.response.data, "error");
            this.show = false;
          });
      }
    },
    back() {
      // this.$router.push({ name : 'inspectionbookofcontractor'})
      this.$router.go(-1);
    },
    AddOked() {
      if (this.editedIndex1 > -1) {
        Object.assign(this.Request.specialists[this.editedIndex1], this.tabrow);
      } else {
        this.Request.specialists.push(this.tabrow);
      }
      this.modal = false;
      this.tabrow = {};
    },
    AddInspectorsTableInfo() {
      this.items.forEach((item) => {
        if (item.isSelected) {
          var duplicateInspector = this.RequestTable.map(
            (item) => item.employeeId
          );
          if (duplicateInspector.includes(item.employeeId)) {
            this.makeToast(this.$t("dublicateperson"), "error");
            return false;
          } else {
            this.RequestTable.push(item);
          }
        }
      });
      this.modalInspectorsInfo = false;
    },

    AddAuditorsTable() {
      if (this.editedIndex1 > -1) {
        Object.assign(
          this.Request.involvedAuditors[this.editedIndex1],
          this.tabrowAuditors
        );
      } else {
        this.Request.involvedAuditors.push(this.tabrowAuditors);
      }
      this.modalAuditors = false;
      this.tabrowAuditors = {};
    },
    AddInspectorsTable() {
      if (this.editedIndex1 > -1) {
        Object.assign(
          this.Request.involvedInspectors[this.editedIndex1],
          this.tabrowInspectors
        );
      } else {
        this.Request.involvedInspectors.push(this.tabrowInspectors);
      }
      this.modalInspectors = false;
    },
    SaveData() {
      var data = {};
      data.id = this.Request.id;
      data.statusId = this.Request.statusId;
      data.inspectionOrganizationAddres = this.Request.inspectionOrganizationAddres;
      data.inspectionOrganizationCeo = this.Request.inspectionOrganizationCeo;
      data.inspectionOrganizationName = this.Request.inspectionOrganizationName;
      data.criminalCaseInfo = this.Request.criminalCaseInfo;
      data.inspectionOrganizationPostalAddres = this.Request.inspectionOrganizationPostalAddres;
      data.orderNumber = this.Request.orderNumber;
      data.requestId = this.Request.requestId;
      data.docDate = this.Request.docDate;
      data.startDate = this.Request.startDate;
      data.endDate = this.Request.endDate;
      data.checkDaysNumber = this.Request.checkDaysNumber;
      data.rate = this.Request.rate;
      data.controlFunctions = this.Request.controlFunctions;
      data.inspectors = this.RequestTable;
      data.involvedAuditors = this.Request.involvedAuditors;
      data.involvedInspectors = this.Request.involvedInspectors;
      data.specialists = this.Request.specialists;
      data.comment = this.Request.comment;

      InspectionBookService.Update(data)
        .then((res) => {
          this.makeToast(this.$t("SaveSuccess"), "success");
          this.$router.push({ name: `inspectionbookofcontractor` });
        })
        .catch((error) => {
          this.makeToast(error.response.data.errors, "error");
        });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style>
.my-select .vs__dropdown-toggle {
  padding: 12px 10px !important;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #f7f7f7;
}
.cardStyleEdit {
  padding: 5px;
  background-color: #eaf2ff;
  border-radius: 5px;
}
.styleText {
  font-weight: 900;
}
/* .my-input{
  background-color: white !important;
}
.my-input input{
  background-color: white !important;
}
.custom-datepicker{
  background-color: white !important;
}
.mx-datepicker{
  background-color: white !important;
}
.my-custom-date{
  background-color: white !important;
}
.vs__dropdown-toggle{
  background-color: white !important;
} */
</style>
